import _ from 'lodash';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import ResultsTable from 'common/components/ResultsTable';
import Checkbox from 'common/components/Checkbox';
import Dropdown from 'common/components/Dropdown';

class ExampleCellComponent extends Component {
  render() {
    const { liar } = this.props;
    if (liar) {
      return this.props.alpha < 0.5 ? <b>{'Above 0.5 (wink)'}</b> : <i>{'Below 0.5 (wink)'}</i>;
    } else {
      return this.props.alpha > 0.5 ? <b>{'Above 0.5'}</b> : <i>{'Below 0.5'}</i>;
    }
  }
}

const newRow = () => ({
  'alpha': Math.random(),
  'beta': Math.random(),
  'gamma': Math.random(),
  'delta': Math.random(),
  'epsilon': Math.random(),
  'zeta': Math.random(),
  'eta': Math.random(),
  'theta': Math.random(),
  'iota': Math.random(),
  'kappa': Math.random()
});

class ResultsTableDemo extends Component {
  constructor() {
    super();
    this.state = {
      loadingData: false,
      data: []
    };
    setInterval(
      _.before(10, this.addRow),
      200
    );
  }

  renderBoolField = (field) => {
    const onChange = () => {
      this.setState({
        [field]: !this.state[field]
      });
    };
    return (
      <form>
        <Checkbox id={`${field}-checkbox`} checked={this.state[field]} onChange={onChange}>{field}</Checkbox>
      </form>
    );
  }

  renderDropdownField = (field, values) => {
    const options = values.map((option) => ({ title: option, value: option }));
    const onSelection = (option) => {
      this.setState({
        [field]: option.value
      });
    };
    return (<Dropdown options={options} value={this.state[field]} onSelection={onSelection} />);
  }

  addRow = () => {
    const { data } = this.state;
    data.push(newRow());
    this.setState({ data });
  }

  render() {
    const {
      loadingData,
      data
    } = this.state;

    const tableProps = {
      noResultsMessage: 'A custom message telling your user that there are no results.',
      data,
      loadingData
    };

    return (
      <div>
        <div>
          <h5>Variant</h5>
          {this.renderBoolField('loadingData')}
          <button onClick={this.addRow}>Add row</button>
          <button onClick={this.delRow}>Remove row</button>
        </div>
        <div className="styleguide-example">
          <ResultsTable {...tableProps}>
            { /* Plain row, rendered as a text node. */ }
            <ResultsTable.Column header="Alpha" dataIndex="alpha" onSort={() => {prompt('Did you know there is a skeleton hiding inside you?'); alert('It is pretty spooky, right?!');}} />
            { /* simple component override */ }
            <ResultsTable.Column header="Beta" dataIndex="beta" cellComponent={ExampleCellComponent} />
            { /* simple component override with custom props */ }
            <ResultsTable.Column header="Gamma" dataIndex="gamma" cellComponent={ExampleCellComponent} cellProps={{ liar: true }} />
            { /* custom render function, highlighted (active) */ }
            <ResultsTable.Column header="delta" dataIndex="delta" isActive children={(value) => <a href="https://example.com">{value}</a>}/>
          </ResultsTable>
        </div>
        <div className="styleguide-code-example">
          <pre>
{
`const props = ${JSON.stringify(tableProps, null, 2)};
`
}
          </pre>
        </div>
      </div>
    );
  }
}

$(() => {
  ReactDOM.render(
    React.createElement(ResultsTableDemo),
    document.getElementById('component-demo')
  );
});
